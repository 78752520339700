// Here you can add other styles

.text-orange-500{--text-opacity:1;color:#ff5a1f;color:rgba(255,90,31,var(--text-opacity))}
.bg-orange-100{--bg-opacity:1;background-color:#feecdc;background-color:rgba(254,236,220,var(--bg-opacity))}
.rounded-full{border-radius:9999px}
.card-title{text-align: center;}
.text-green-500{--text-opacity:1;color:#0e9f6e;color:rgba(14,159,110,var(--text-opacity))}
.bg-green-100{--bg-opacity:1;background-color:#def7ec;background-color:rgba(222,247,236,var(--bg-opacity))}
.text-blue-500{--text-opacity:1;color:#3f83f8;color:rgba(63,131,248,var(--text-opacity))}
.bg-blue-100{--bg-opacity:1;background-color:#e1effe;background-color:rgba(225,239,254,var(--bg-opacity))}
.text-teal-500{--text-opacity:1;color:#0694a2;color:rgba(6,148,162,var(--text-opacity))}
.bg-teal-100{--bg-opacity:1;background-color:#d5f5f6;background-color:rgba(213,245,246,var(--bg-opacity))}
.text-red-700{--text-opacity:1;color:#c81e1e;color:rgba(200,30,30,var(--text-opacity))}
.bg-red-100{--bg-opacity:1;background-color:#fde8e8;background-color:rgba(253,232,232,var(--bg-opacity))}
.d-block{
    font-size: 1.2rem;
}
.clickable a,
.clickable-img{

    cursor: pointer;
}
.clickable{
text-align: right;
cursor: pointer;
}

.prop-btn,
.prop-btn:hover
{
    text-decoration: none;
    border-radius: 5px;
    border:1px solid #1d5bc7;

    background-color: white;
    color: black;

    cursor: pointer;
width: fit-content;
padding: 5px 10px 5px 10px;
}
.prop-btn-active,
.prop-btn-active:hover
{
    width: fit-content;
    padding: 5px 10px 5px 10px;
cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
    border:1px solid #1d5bc7;

    background-color: #1d5bc7;
    color: white;

}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
    // background: none;
    // background-color: #1d5bc7;
    color: #fff;
  }
  .react-datepicker__day--highlighted-custom{
    color:#1d5bc7
  }
@media(max-width:810px)
{
    .prop-row{
  font-size: 12px;
  margin-bottom: 10px;
    }
}
.react-datepicker__day--highlighted
{

  border-radius: 0.3rem;
  background-color: #1d5bc7 !important;
  color: #fff;

}
.react-datepicker__day--highlighted-custom-1 {
   border-radius: 0.3rem;
  background-color: #1d5bc7 !important;
  color: #fff !important;
}
.css-t3ipsp-control {
  box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25) !important;
  border-color: var(--cui-input-focus-border-color, #998fed) !important;

}
.selectAgent.css-t3ipsp-control:hover{
  color: var(--cui-input-focus-color, var(--cui-body-color)) !important;
  background-color: var(--cui-input-focus-bg, var(--cui-body-bg)) !important;
  border-color: var(--cui-input-focus-border-color, #998fed) !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25) !important;
}
.select__control .css-13cymwt-control:focus {
  color: var(--cui-input-focus-color, var(--cui-body-color)) !important;
  background-color: var(--cui-input-focus-bg, var(--cui-body-bg)) !important;
  border-color: var(--cui-input-focus-border-color, #998fed) !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25) !important;
}
.css-1u9des2-indicatorSeparator {
  width: 0 !important;
  display: none !important;
}
.css-1hb7zxy-IndicatorsContainer > :last-child  {
  display: none !important;
  // padding-inline: 5px !important;
}
// .react-datepicker__day--selected
//  {
//   border-radius: 0.3rem;
//   background-color: transparent !important;
//   color: #000000 !important;
//  }
